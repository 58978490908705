<template>
  <base-form
    model="announcement"
    @save="announcement_id && !duplicating ? update() : create()"
    :fields="fields"
    :loading="$apollo.queries.announcement.loading"
    :currentValue="announcement"
    :defaultValue="baseItem"
    @input="newValue = $event"
    :saving="saving"
    :duplicating="duplicating"
    @cancel="$emit('cancel')"
    ref="form"
  />
</template>

<script>
import gql from "graphql-tag";
import BaseForm from "./BaseForm.vue";
import { createMutationBuilder, updateMutationBuilder } from "../../graphql/mutations";
import { required } from "vuelidate/lib/validators";
import { ANNOUNCEMENTS_ROW_FRAGMENT } from "../tables/AnnouncementsTable.vue";

const ANNOUNCEMENT_FRAGMENT = gql`
  fragment announcement on Announcement {
    id
    title
    text
    start
    end
  }
`;

export default {
  components: { BaseForm },
  name: "AnnouncementForm",
  props: {
    announcement_id: {},
    baseItem: { default: () => ({}) },
    duplicating: Boolean,
  },

  watch: {
    announcement_id(val) {
      if (val == null) this.announcement = {};
    },
  },

  apollo: {
    announcement: {
      query: gql`
        query Announcement($id: ID!) {
          announcement(id: $id) {
            ...announcement
          }
        }
        ${ANNOUNCEMENT_FRAGMENT}
      `,
      variables() {
        return { id: this.announcement_id };
      },
      skip() {
        return this.announcement_id == null;
      },
    },
  },
  data() {
    return {
      newValue: {},
      announcement: {},
      saving: false,
      fields: [
        {
          name: "start",
          type: "datetime",
          validation: {
            required,
          },
        },
        {
          name: "end",
          type: "datetime",
          validation: {
            required,
          },
        },
        {
          name: "title",
          validation: {
            required,
          },
          cols: 12,
        },
        {
          name: "text",
          type: "textarea",
          validation: {
            required,
          },
          last: true,
          cols: 12,
        },
      ],
    };
  },

  computed: {},

  methods: {
    update() {
      this.saving = true;

      // eslint-disable-next-line no-unused-vars
      const { announcementable_id, announcementable_type, ...data } = this.newValue;

      this.$apollo
        .mutate({
          mutation: updateMutationBuilder({
            model: "Announcement",
            fragment: ANNOUNCEMENTS_ROW_FRAGMENT,
            fragment_name: "announcementRow",
          }),
          variables: {
            id: this.announcement_id,
            input: data,
          },
        })
        .then(() => {
          this.$refs.form.resetInfo();
          this.saving = false;
          this.$emit("save");
        })
        .catch((error) => {
          console.error(error);
          this.saving = false;
        });
    },

    create() {
      this.saving = true;

      this.$apollo
        .mutate({
          mutation: createMutationBuilder({
            model: "Announcement",
            fragment: ANNOUNCEMENTS_ROW_FRAGMENT,
            fragment_name: "announcementRow",
          }),
          variables: {
            input: { ...this.newValue },
          },
        })
        .then(({ data: { createAnnouncement } }) => {
          this.$refs.form.resetInfo();
          this.saving = false;
          this.$emit("created", createAnnouncement);
        })
        .catch((error) => {
          console.error(error);
          this.saving = false;
        });
    },
  },
};
</script>

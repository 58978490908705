<template>
  <v-select
    :items="managedAreas"
    item-text="name"
    item-value="id"
    :value="value"
    @input="(value) => $emit('input', value)"
    return-object
    :loading="loading"
    :label="$t('area.name')"
  ></v-select>
</template>

<script>
import gql from "graphql-tag";
export default {
  data() {
    return {
      managedAreas: [],
    };
  },
  props: ["value"],
  apollo: {
    managedAreas: {
      query: gql`
        query ManagedAreas {
          me {
            id
            managedAreas {
              id
              name
            }
          }
        }
      `,
      update: (data) => data.me.managedAreas,
    },
  },
  computed: {
    loading() {
      return this.$apollo.queries.managedAreas.loading;
    },
  },
};
</script>

<style>
</style>
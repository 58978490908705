<template>
  <v-container>
    <v-card class="overflow-hidden mb-4" rounded="lg">
      <v-card-text>
        <v-select
          :items="announcementableTypes"
          v-model="announcementable_type"
          :label="$t('announcement.announcementable_type')"
        ></v-select>
        <managed-residences-select v-if="announcementable_type == 'RESIDENCE'" v-model="selectedResidence" />
        <managed-areas-select v-if="announcementable_type == 'AREA'" v-model="selectedArea" />
      </v-card-text>
    </v-card>

    <v-card v-if="filled" class="overflow-hidden" rounded="lg">
      <AnnouncementsTable
        v-if="announcementable && !$apollo.queries.announcementable.loading"
        :announcementable="announcementable"
      >
      </AnnouncementsTable>
      <v-skeleton-loader type="table" v-else />
    </v-card>
  </v-container>
</template>

<script>
import AnnouncementsTable, { ANNOUNCEMENTS_TABLE_FRAGMENT } from "../components/tables/AnnouncementsTable.vue";
import ManagedAreasSelect from "../components/inputs/ManagedAreasSelect.vue";
import ManagedResidencesSelect from "../components/inputs/ManagedResidencesSelect.vue";
import gql from "graphql-tag";
import { ucFirst } from "../apollo/helpers";

export default {
  name: "Announcements",
  components: { AnnouncementsTable, ManagedAreasSelect, ManagedResidencesSelect },

  data() {
    return {
      announcements: [],
      selectedResidence: null,
      selectedArea: null,
      announcementable_type: null,
    };
  },

  computed: {
    announcementable_id() {
      switch (this.announcementable_type) {
        case "CLIENT":
          return this.me?.client.id;
        case "AREA":
          return this.selectedArea?.id;
        case "RESIDENCE":
          return this.selectedResidence?.id;
        default:
          return null;
      }
    },

    announcementableTypes() {
      return this.me?.allowedAnnouncementableTypes.map((t) => ({
        value: t,
        text: this.$t(`options.announcement.announcementable_type.${t}`),
      }));
    },

    query() {
      if (this.announcementable_type == null) return null;
      const typename_lc = this.announcementable_type.toLowerCase();
      const typename_uc = ucFirst(this.announcementable_type.toLowerCase());
      return gql`
      query ${typename_uc}Announcements($id: ID!){
        ${typename_lc}(id: $id) {
            id
            ...announcementsTable
          }
        }
        ${ANNOUNCEMENTS_TABLE_FRAGMENT}
      `;
    },

    filled() {
      return this.announcementable_id != null && this.announcementable_type != null;
    },
  },

  apollo: {
    announcementable: {
      query() {
        return this.query;
      },
      variables() {
        return { id: this.announcementable_id };
      },
      update(data) {
        return data[this.announcementable_type.toLowerCase()];
      },
      skip() {
        return !this.filled;
      },
    },
    me: {
      query: gql`
        query Me {
          me {
            id
            allowedAnnouncementableTypes
            client {
              id
            }
          }
        }
      `,
      update(data) {
        if (data.me.allowedAnnouncementableTypes.length != 0)
          this.announcementable_type = data.me.allowedAnnouncementableTypes[0];
        return data.me;
      },
    },
  },
};
</script>

<template>
  <base-table
    :title="$tc('models.announcement', 2)"
    :items="announcementable.announcements"
    :headers="headers"
    :tableProps="tableProps"
    :view-only="!announcementable.canCreateAnnouncements"
    @deleteItem="deleteAnnouncement"
  >
    <template v-slot:form="{ selected_id, close, duplicating }">
      <announcement-form
        :announcement_id="selected_id"
        :baseItem="{
          announcementable_type: announcementable.__typename.toUpperCase(),
          announcementable_id: announcementable.id,
        }"
        @cancel="close"
        :duplicating="duplicating"
        @save="close"
        @created="(announcement) => announcementCreated(announcement, close)"
      />
    </template>
  </base-table>
</template>

<script>
export const ANNOUNCEMENTS_ROW_FRAGMENT = gql`
  fragment announcementRow on Announcement {
    id
    title
    text
    start
    end
  }
`;

export const ANNOUNCEMENTS_TABLE_FRAGMENT = gql`
  fragment announcementsTable on Announcementable {
    id
    canCreateAnnouncements
    announcements {
      ...announcementRow
    }
  }
  ${ANNOUNCEMENTS_ROW_FRAGMENT}
`;

import BaseTable from "./BaseTable.vue";
import AnnouncementForm from "../forms/AnnouncementForm.vue";
import gql from "graphql-tag";
import moment from "moment";
import { deleteModelMutation } from "../../graphql/mutations";
import { addToCacheFragmentArray, removeFromCacheFragmentArray } from "../../apollo/helpers";

export default {
  components: { BaseTable, AnnouncementForm },
  name: "AnnouncementsTable",
  props: {
    announcementable: { type: Object, required: true },
    tableProps: { type: Object, default: () => ({}) },
  },

  data() {
    return {
      headers: [
        {
          value: "title",
          text: this.$t("announcement.title"),
        },
        {
          value: "text",
          text: this.$t("announcement.text"),
        },
        {
          value: "start",
          text: this.$t("announcement.start"),
          type: "timestamp",
        },
        {
          value: "end",
          text: this.$t("announcement.end"),
          type: "timestamp",
        },
      ],

      moment,
    };
  },

  methods: {
    announcementCreated(newElement, close) {
      addToCacheFragmentArray(this.$apollo.provider.defaultClient, {
        fragment_info: {
          fragment: ANNOUNCEMENTS_TABLE_FRAGMENT,
          fragmentName: "announcementsTable",
          id: `${this.announcementable.__typename}:${this.announcementable.id}`,
        },
        array_path: "announcements",
        newElement,
      });
      close();
    },

    deleteAnnouncement(announcement) {
      this.$apollo
        .mutate({
          mutation: deleteModelMutation("Announcement"),
          variables: { id: announcement.id },
        })
        .then(({ data: { deleteAnnouncement } }) =>
          removeFromCacheFragmentArray(this.$apollo.provider.defaultClient, {
            fragment_info: {
              fragment: ANNOUNCEMENTS_TABLE_FRAGMENT,
              fragmentName: "announcementsTable",
              id: `${this.announcementable.__typename}:${this.announcementable.id}`,
            },
            array_path: "announcements",
            id: deleteAnnouncement.id,
          })
        )
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>
